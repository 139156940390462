AFRAME.registerComponent('sunshine-cone', {
    schema: {
      height: {type: 'number', default: 5},
      radiusBottom: {type: 'number', default: 2},
      radiusTop: {type: 'number', default: 1},
      segmentsRadial: {type: 'int', default: 36},
      segmentsHeight: {type: 'int', default: 1}
    },
    init: function () {
      const data = this.data;
      const el = this.el;

      // Create geometry
      const geometry = new THREE.CylinderGeometry(
        data.radiusTop,
        data.radiusBottom,
        data.height,
        data.segmentsRadial,
        data.segmentsHeight,
        true
      );

      // Create material
      const material = new THREE.MeshBasicMaterial({
        color: 0xFFFF00,
        transparent: true,
        opacity: 0.1,
        side: THREE.OneSide
      });

      // Create mesh
      const mesh = new THREE.Mesh(geometry, material);

      // Apply mesh to entity
      el.setObject3D('mesh', mesh);
    }
  });